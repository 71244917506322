@import "@assets/css/sass/custom/_color.scss";

.chat-attachment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 60%;
  position: relative;

  &.sent {
    align-items: flex-end;
  }

  .chat-container {
    padding: 10px 15px;
    border-radius: 20px;
    font-weight: 300;
    gap: 5px;
    display: flex;
    line-height: 20px;
    max-width: 100%;

    &.sent {
      background: $blue;
      color: $white;

      .icon {
        background-color: $primary-headline;
      }
    }

    &.received {
      background: $secondary-button;
      color: $secondary-text;

      .icon {
        background-color: $light-blue-gray;
      }
    }

    &.failed {
      background: $chat-error;
      color: $secondary-text;
      cursor: pointer;

      .icon {
        background-color: $error;
      }
    }

    .icon {
      min-width: 31px;
      width: 31px;
      height: 31px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
    }
  }

  .file-name {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .file-size {
    font-size: 12px;
    line-height: 14px;
  }

  .timestamp {
    color: $blue-black;

    .icon-sent {
      > path {
        fill: $blue-black;
      }
    }

    .icon-check {
      > path {
        stroke: $blue-black;
      }
    }
  }
}