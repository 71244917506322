@import "@assets/css/sass/custom/_color.scss";

.document-modal {
  #react-doc-viewer {
    background: url("../../../../assets/gif/loader.gif") no-repeat;
    background-size: 70px;
    background-position: center;

    #header-bar {
      position: relative;
    }
    #proxy-renderer {
      overflow-y: hidden !important;
    }
  }

  .close-button-style {
    position: absolute;
    top: 28px;
    z-index: 1000;
    right: 55px;
  }

  .download-button-style {
    // position: absolute;
    top: 28px;
    z-index: 1000;
    right: 150px;
    text-decoration: none;
    color: black;
  }

  .disabled-download-button {
    pointer-events: none;
    cursor: default;
    color: grey;
  }

  #pdf-download {
    display: none !important;
  }

  .doc-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 30px;
    height: 30px;
  }

  .modal-title {
    width:  100%;
  }

  .modal-body.pdf-viewer-active {
    overflow-y: scroll; /* Enable scrolling */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .modal-body.pdf-viewer-active {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .modal-body.pdf-viewer-active::-webkit-scrollbar {
    display: none;
  }
}