@import "@assets/css/sass/custom/_color.scss";

.chat-image {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: $blue-black;
  max-width: 80%;

  img {
    width: 400px;
    height: 270px;
    border-radius: 16px;
    background: $light-blue;
    object-fit: cover;
    max-width: 100%;

    &.failed {
      border: 2px solid $error;
    }
  }

  &.sent {
    align-items: flex-end;
  }

  .icon-check {
    > path {
      stroke: $blue-black;
    }
  }

  .icon-sent {
    > path {
      fill: $blue-black;
    }
  }
}