@import "@assets/css/sass/custom/_color.scss";

.chat-input {
  background: $secondary-bg;
  z-index: 0;

  .icon > path {
    fill: $blue-black;
  }

  .icon-send > path {
    fill: $blue;
  }

  .btn-link {
    height: 45px;
    min-width: 45px;
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover {
      background-color: rgba(#000000, 0.1);
    }
  }

  .btn.disabled {
    background-color: transparent !important;
  }
}