@import "@assets/css/sass/custom/_color.scss";

.user-avatar {
  border-radius: 100px;
  background: $avatar-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue-black;
  font-size: 14px;

  img {
    border-radius: 100px;
  }
}