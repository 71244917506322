@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.webcam-capture-modal {
  .modal-header {
    position: absolute;
    right: 0;
    z-index: 1;
    border-bottom: none;
  }

  .modal-body {
    min-height: 598px;
  }

  .modal-footer {
    border-top: none;

    .btn-group {
      border-radius: 8px;
      border: var(--bs-border-width) solid var(--bs-primary);
      padding: 0.5px;

      svg {
        margin-bottom: 2px;
      }

      .btn.active, .btn:hover {
        svg > path {
          fill: var(--bs-white);
        }
      }

      .btn {
        border-radius: 7px;
        // border: none !important;

        &.active, &:hover {
          background: var(--bs-primary);
        }
      }
    }

    .btn-capture {
      height: 60px;
      width: 60px;
      border-radius: 30px;

      &:hover {
        filter: brightness(110%);
      }
      
      svg {
        margin-bottom: 2px;
      }

      .stop {
        background: var(--bs-white);
        width: 30px;
        height: 30px;
        border-radius: 4px;
        margin: auto;
      }
    }

    .btn-upload {
      font-weight: bold;
      background: var(--bs-primary);
      border-radius: 10px;
      width: auto;

      &:hover {
        filter: brightness(110%);
      }
    }

    .btn-retry {
      svg > path {
        fill: var(--bs-white);
      }
    }

    .btn-control {
      flex: 1 1 0 !important;
    }
  }
}


@include media-breakpoint-down(lg) {
  .webcam-capture-modal {
    .webcam {
      width: 500px !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .webcam-capture-modal {
    .modal-header {
      padding: 15px !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .webcam-capture-modal {
    .webcam {
      width: 350px !important;
    }
  }
}
