@import './assets/css/sass/custom/_color.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-circular-progressbar/dist/styles.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
// @import '~react-big-calendar/lib/css/react-big-calendar.css';
// @import '~react-table/react-table.css';
// @import './assets/css/sass/themes/gogo.light.green.scss';
@import './assets/css/sass/custom/_system-wide.scss';
@import './assets/css/sass/custom/_forms.scss';
// @import '../node_modules/react-redux-toastr/src/styles/index';
// @import '~rc-switch/assets/index.css';
