// @import "./fonts.scss";
@import "_color.scss";
@import "./_helper.scss";
@import "./_modal.scss";

html {
  background-color: #ffff !important;
  height: 100%;
  overflow: auto;
}

body, #app, #app>div {
  height: 100%;
}

main {
  margin: 0 !important;
}

#root {
  height: 100%;
}

.container {
  max-width: 100% !important;
  margin: 0;
  padding: 0 !important;
}

body {
  font-family: "SFPro";
  font-size: 16px;
}

.btn-link {
  color: $primary;
}

.btn-primary {
  --bs-btn-border-color: #{$blue};
  --bs-btn-color: #{$body-bg};
  --bs-btn-disabled-color: #{$body-bg};
  --bs-btn-hover-color: #{$body-bg};
  width: 100%;
  text-align: center;
  border: 0;
  border-radius: 14px;
  background-color: $blue;
  font-weight: bold;
  font-size: 15px;
  span {
    font-size: 15px;
    font-weight: 700;
    color: $text-white;
  }

  &:not(.btn-sm) {
    height: 48px;
    border-radius: 14px;
  }
}

.btn-error {
    --bs-btn-border-color: #{$red};
    --bs-btn-color: #{$body-bg};
    --bs-btn-disabled-color: #{$body-bg};
    --bs-btn-hover-color: #{$body-bg};
    width: 100%;
    height: 48px;
    text-align: center;
    border: 0;
    border-radius: 14px;
    background-color: $red;
    font-weight: bold;
    font-size: 15px;
    span {
      font-size: 15px;
      font-weight: 700;
      color: $text-white;
    }

    &:hover {
      background-color: #B60000;
    }
}

.btn-secondary {
  width: 100%;
  text-align: center;
  border: 0;
  border-radius: 14px;
  background-color: $secondary-button;
  font-weight: bold;
  font-size: 15px;
  span {
    font-size: 15px;
    font-weight: 700;
    color: $secondary-button-text;
  }

  &:not(.btn-sm) {
    height: 48px;
    border-radius: 14px;
  }

  &:hover {
    background-color: #85ADE1;
  }
}

.btn-back {
  color: $blue-black;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    svg > path {
      stroke: $primary;
    }
  }
}

.h1 {
  font-weight: bold;
  font-size: 34px;
}

.h2 {
  font-weight: bold;
  font-size: 28px;
}

.s1 {
  font-weight: bold;
  font-size: 22px;
}

.s2 {
  font-weight: bold;
  font-size: 20px;
}

.b1 {
  font-size: 16px;
}

.b2 {
  font-weight: bold;
  font-size: 16px;
}

.b3 {
  font-size: 14px;
}

.b4 {
  font-weight: bold;
  font-size: 14px;
}

.toast {
  font-weight: 600;
  font-size: 14px;
}

.c1 {
  font-size: 12px;
}

.c2 {
  font-weight: 600;
  font-size: 14px;
}

.o1 {
  font-size: 10px;
}

.o2 {
  font-weight: 600;
  font-size: 10px;
}

.btn-primary:hover {
  background-color: $hover-button;
}

.btn.disabled {
  background-color: $disabled-color;
  border-color: $disabled-color;
}

textarea {
  resize: none;
  color: $black !important;
}

.w-cursor {
  cursor: pointer !important;
}

.no-cursor {
  cursor: default !important;
}

.font-weight-bold {
  font-weight: bold;
}

.large-title {
  font-weight: bold;
  font-size: 34px;
}

.title1 {
  font-weight: bold;
  font-size: 28px;
}

.title2 {
  font-weight: bold;
  font-size: 22px;
}

.title3 {
  font-weight: bold;
  font-size: 20px;
}

.sort-label,
.filter-label,
.bulk-label {
  font-weight: bold;
  font-size: 14px;
}

.headline {
  font-weight: 600;
  font-size: 17px;
}

.placeholder {
  font-size: 16px;
}

.sidebar {
  color: $black;
}

.dropdown-item {
  color: $secondary-text;
  &:active {
    color: white !important;
  }
}

.dropdown-menu {
  border-color: $light !important;
}

.error-container {
  color: #cf1827;
  background-color: #fae8e9;
  border-radius: 15px;
  font-size: 12px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.input {
    width: 100%;
    border-radius: 8px;
    background-color: $light-blue;
}

.form-floating {
  label {
    color:$blue-black,
  }

  ::after {
   padding: 0;
   border: 0;
   margin: 0;
  }
}

.input.focus {
  background-color: red !important;
}

.form-label {
  span {
    background-color: $light-blue;
    padding: 0%;
  }
}

.text-muted {
  color: $border-stroke-color !important;
}

.input-group {
  > a {
    border: none !important;
  }

  .input-group-text {
    background-color: $light-blue;
  }

  &:focus-within {
    box-shadow: 0 0 0 3px $input-border;
    border-radius: 8px;;
  }

  .form-control {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &.is-valid {
    .input-group-text {
      border-color: $success;
    }
  }

  &.is-invalid {
    .input-group-text {
      border-color: $danger;
      background-color: $error-body;

      &:focus {
        box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.2) !important;
      }
    }

    label::after {
      background-color: transparent !important;
    }
  }
}

.form-control {
  background-color: $light-blue;
  border-color: $light-blue-border;
  border-radius: 8px;


  &.is-invalid {
    background-color: $error-body;
  }
}

.form-control:focus {
  background-color: $light-blue;
  box-shadow: 0 0 0 3px $input-border;
  border-color: $light-blue-border;
}

.custom-dropdown {
  &:after {
    display: none;
  }
}

.list-btn {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.text-black {
  color: $black;
}

.breadcrumb-item {
  font-size: 16px;
  color: #8f8f8f;
}

.list-recordsFound {
  font-size: 14px;
  color: #8f8f8f;
}

.td-action {
  overflow: unset !important;

  .btn-group {
    padding-right: 15px;
    .btn {
      padding-top: 6px;
      text-transform: uppercase;
    }
  }
}

.required {
  color: $primary !important;
}

.custom-modal-dialog-centered {
  box-shadow: none !important;
}

.pagination-bottom {

  .btn-outline-primary {
    width: 60px;
    height: 30px;
  }
}

.btn-outline-primary {
  border-width: 2px;
  font-weight: bold;
}

.rt-th {
  font-weight: 600;
  font-size: 17px;
}

.rt-td, .rt-th {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.basic-multi-select {
  .select__control {
    height: 45px;
    border-color: #8f8f8f;
    border-radius: 8px;
  }
}
.react-multiselect {
  &__control {
    height: 45px;
    border-color: #8f8f8f;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
  }
}

.custom-dropdown {
  > button {
    padding: 2px 15px !important;
    background-color: white;
    color: $secondary;
    border: 2px solid $primary;
    font-weight: bold;
    color: $primary;
    &:hover {
      background-color: $primary;
      border: 2px solid $primary;
    }
  }
}

.custom-dropdown-menu {
  border-radius: 12px !important;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  button,
  .dropdown-item {
    font-weight: bold;
    color: #4a4a4a !important;
    text-transform: uppercase;
    text-align: left;
  }
}

.custom-badge {
  padding: 10px 15px !important;
  border-radius: 50px;
  text-transform: capitalize;
}

.standard {
  background-color: $standard !important;
  color: black;
}

.rush {
  background-color: $rush !important;
}

.custom-textarea {
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  border-color: $border-stroke-color;
}

.dropdown-menu{
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
}

.custom-select__multi-value{
  background-color: #FAE8E9 !important;
  border-radius: 5px;
  font-weight: bold;
}

.version-container {
  position: relative;
  bottom: 0;
  text-align: center;
  width: 100%;
}
