@import "@assets/css/sass/custom/_color.scss";

.ticket-conversation-page {
  min-height: 100vh;

  .chat-content {
    height: calc(100vh - 192px);
    overflow-y: auto;

    .container-md {
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      min-height: 100%;
    }
  }

  .completed-chat {
    height: calc(100vh - 95px);
    padding-bottom: 30px;
  }

  .background {
    width: 100%;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .navbar {
    background-color: $secondary-bg;
    z-index: 0;
    .container-md {
      padding-left: 0;
      padding-right: 0;
    }
  }


  .timestamp {
    font-size: 10px;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .date {
    font-size: 12px;
    color: $blue-black;
    text-transform: uppercase;
    text-align: center;
  }

  .retry {
    font-size: 10px;
    color: $error;
  }

  .text-online {
    color: $blue-black;
    font-size: 13px;
  }
}