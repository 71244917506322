@import "@assets/css/sass/custom/_color.scss";

.chat-message {
  padding: 10px 15px;
  border-radius: 16px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 60%;

  &.sent {
    background: $blue;
    color: $white;

    .icon > path {
      stroke: $white;
    }
  }

  &.received {
    background: $secondary-button;
    color: $secondary-text;
  }

  &.failed {
    background: $chat-error;
    color: $secondary-text;
    cursor: pointer;
  }

  .wrap {
    word-break: break-word;
    white-space: pre-wrap;
  }
}