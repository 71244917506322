@import "@assets/css/sass/custom/_color.scss";

.navbar {
  background-color: $secondary-bg !important;

  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .user-image:hover{
    border: 3px solid $input-border;
  }
  .dropdown-toggle {
    border-radius: 50%;
  }

  .dropdown-text {
    color: $secondary-text;
  }

  .dropdown-item.active {
    background-color: #DFEEFA;
    color: #171C23;
  }

  .dropdown-menu {
    border: 0;
  }

  .dropdown-item:active {
    color: #171C23 !important;
    background-color: #DFEEFA;
  }

  .btn.btn-empty:active {
    border-radius: 200px;
    border:2px solid $input-border;
  }

  .btn.btn-empty:focus {
    border-radius: 200px;
    border:2px solid $input-border;
  }

  .menu-dropdown-container {
    button {
      background-color: transparent;

      &:hover {
        background-color: #DEE8F5;
      }
    }
  }

}

.white-nav {
  background-color: $text-white !important;
}