.form-control {
  --bs-border-color: #{$light-blue-border};
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../svg/calendar.svg") no-repeat;
}

.invalid-feedback {
  text-align: left;
}

/* react-select */

.react-select__control {
  border-radius: 8px !important;
  border-color: $light-blue-border !important;
  background: $light-blue !important;

  .react-select__input {
    color: var(--bs-body-color) !important;
  }
}

.react-select__menu {
  z-index: 3 !important;
}